var pageReloaded;

function hasPageReloaded() {
    if(pageReloaded === undefined) {
        pageReloaded = false;
        if (window.performance !== undefined) {
            // first check new function
            if (typeof performance.getEntriesByType === "function") {
                var results = performance.getEntriesByType("navigation");
                if (Array.isArray(results) && results.length > 0 && typeof results[0].type === "string") {
                    pageReloaded = results[0].type === "reload";
                }
            }
            // if not found check deprecated function
            else if (performance.navigation !== undefined && typeof performance.navigation.type === "number") {
                pageReloaded = performance.navigation.type === 1;
            }
        }
    }
    return pageReloaded;
}

module.exports = hasPageReloaded