"use strict";
var loginFunctions = require("./loginUtil.js");
var Version = require("../../../cloudflow/Version/js/Version.js");
var Location = require("../../../cloudflow/CloudflowUtil/js/Location.js");
/**
 * Handler that is executed when the login has failed
 */

function loginFailedHandler (pReason) {
    if(pReason.available_scopes && pReason.available_scopes.length > 0) {
        $("#scope_options").parent(".cf-form-group").remove();
        // $("#username").hide();
        // $("#password").hide();
        $("#username").css("display", "none");
        $("#password").parent().css("display", "none");
        
        $("#loginStatus").text($.i18n._("nixps-cloudflow-login.select_scope"));
        $("#loginStatus").closest(".cf-form-group").addClass("cf-form-group-has-message");
        if ($("#scope_options").length === 0) {
            $('<div class="cf-form-group cf-form-add-gutter"><select class="cf-form-control cf-form-col-12" id="scope_options"></select></div>').insertAfter($("#password").parent(".cf-form-group"));
        }
        pReason.available_scopes.forEach(function (scope, index) {
            $("#scope_options").append($("<option>").val(scope.name).text(scope.name));
        });
        $("#loginButton").prop("disabled", false);
    } else if(pReason === "1") {
        pReason = $.i18n._("nixps-cloudflow-login.error-user_not_registered");
        $("#loginStatus").text(pReason);
        $("#loginButton").prop("disabled", false);
        $("#loginStatus").closest(".cf-form-group").addClass("cf-form-group-has-error");
    } else {
        $("#loginStatus").text(pReason);
        $("#loginButton").prop("disabled", false);
        $("#loginStatus").closest(".cf-form-group").addClass("cf-form-group-has-error");
    }
    
}

/**
 * Handler that does the login
 */
function loginHandler () {
    var result1 = false;
    var result2 = false;
    var result3 = false
    if(window.sOrigURL && typeof window.sOrigURL === "string" && window.sOrigURL !== "/") {
        //unencoded string test
        var test1 = /<\/?script/i;
        //encoded lower case test
        var test2 = /%3c\/?script/i;
        //encoded Component test
        var test3 = /%3C%2Fscript/i;
        result1 = test1.test(window.sOrigURL);
        result2 = test2.test(window.sOrigURL);
        result3 = test3.test(window.sOrigURL);
    }
    if(result1 === true || result2 === true || result3 === true) {
        loginFailedHandler($.i18n._("nixps-cloudflow-login.error-no_script_tag_allowed"));
    } else {
        $("#loginButton").prop("disabled", true);
        var username = $("#username").val();
        var password = $("#password").val();
        $("#loginButton").prop("disabled", true);
        $("#loginStatus").closest(".cf-form-group").removeClass("cf-form-group-has-error");
        if ($("#scope_options").length > 0) {
            var scope_option = $("#scope_options").val();
            loginFunctions.doMultiScopeLogin(username, password, scope_option, function (pUserId) {
                loginFunctions.setFrameSession(pUserId).then(function () {
                    document.location.assign(window.sOrigURL);
                });
            }, loginFailedHandler);
        } else {
            loginFunctions.doLogin(username, password, function (pUserId) {
                loginFunctions.setFrameSession(pUserId).then(function () {
                    document.location.assign(window.sOrigURL);
                });
            }, loginFailedHandler);
        }
    }
   
   
}

/**
 * Draws the UI and binds the events
 * @param {string} pLanguage 
 */
function renderLogin (pLanguage, pVersionObject) {
    return $.Deferred(function(pDefer){
        if (typeof window.sTitle === "string") {
            document.title = $.i18n._(window.sTitle);
        }
        $('#username').attr('placeholder', $.i18n._('nixps-cloudflow-login.username'));
        $('#password').attr('placeholder', $.i18n._('nixps-cloudflow-login.password'));

        // Set defaults in case the variables are not filled in
        if (window.sOrigURL === undefined) {
            // error during login procedure
            window.sOrigURL = "/";
        } else if (window.sOrigURL === "<!--ORIG-->") {
            window.sOrigURL = window.location.href;
        }

        if (window.sOrigUser === "<!--USERNAME-->") {
            window.sOrigUser = "";
        }

        if (typeof window.sOrigUser === "string" && window.sOrigUser.length > 0) {
            $('#username').val(window.sOrigUser);
        }

        // Try to generate the oauth2 link
        api_defer.auth.generate_oauth2_url('google').then(function (pData) {
            if ((pData.result !== undefined) && (pData.result !== null)) {
                $('a#googleButton').attr('href', pData.result);
                $('div#google').show();
            }
        }, function () {
            /* ignore error */
        });

        // Bind the login button
        $('#loginButton').attr('value', $.i18n._('nixps-cloudflow-login.ok'))
            .prop("disabled", false)
            .css("cursor", "pointer")
            .on("click", loginHandler);

        // Focus the right field
        if ($('#username').val() === '') {
            $('#username').focus();
        } else {
            $('#password').focus();
        }

        // Bind the return keypress
        $(document).keypress(function (pEvent) {
            if (pEvent.keyCode === 13) {
                loginHandler();
            }
        });

        // Show the version text
        if (pVersionObject === undefined) {
            Version.get().then(function(version){
                var loginText = loginFunctions.getVersionText(version.getVersionObject());
                $("#versionText").text(loginText);
            });
        } else {
            var loginText = loginFunctions.getVersionText(pVersionObject);
            $("#versionText").text(loginText);
        }
        
        if (Location.getLocation().toString().indexOf('error=') >= 0) {
            var url = Location.getLocation().toString()
            if(url.substring(url.indexOf('error=')+6).indexOf('&') !== -1) {
                loginFailedHandler(decodeURIComponent(url.substring(url.indexOf('error=')+6, url.indexOf('error=')+6+url.substring(url.indexOf('error=')+6).indexOf('&'))));
            } else {
                loginFailedHandler(decodeURIComponent(url.substring(url.indexOf('error=')+6)));
            }
        }

    
        api_defer.auth.get_identity_providers().then(function(pData){
            if(Array.isArray(pData.result) && pData.result.length > 0) {
                for (var i = 0; pData.result.length > i; i++) {
                    var oauth_type = pData.result[i];
                    //http://www.w3.org/2000/svg
                    // var icon = $('<svg>').attr("xmlns", "http://www.w3.org/2000/svg").attr("viewBox", "0 0 48 48");
                    if(pData.result[i] !== 'none') {
                        var icon = '';
                        var icon_azure = $('<svg aria-hidden="true" role="presentation" data-slug-id="azure" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">'+
                        '<defs>'+
                        '<linearGradient id="azure_ab40b385-f958-420f-bfab-714be1677426-4a336842" x1="-960.606" y1="283.397" x2="-1032.511" y2="70.972" gradientTransform="matrix(1 0 0 -1 1075 318)" gradientUnits="userSpaceOnUse">'+
                            '<stop offset="0" stop-color="#114a8b"></stop>'+
                            '<stop offset="1" stop-color="#0669bc"></stop>'+
                        '</linearGradient>'+
                        '<linearGradient id="azure_f40af90d-72eb-49b3-94b2-2510f1071722-6b298772" x1="-938.144" y1="184.402" x2="-954.778" y2="178.778" gradientTransform="matrix(1 0 0 -1 1075 318)" gradientUnits="userSpaceOnUse">'+
                            '<stop offset="0" stop-opacity=".3"></stop>'+
                            '<stop offset=".071" stop-opacity=".2"></stop>'+
                            '<stop offset=".321" stop-opacity=".1"></stop>'+
                            '<stop offset=".623" stop-opacity=".05"></stop>'+
                            '<stop offset="1" stop-opacity="0"></stop>'+
                        '</linearGradient>'+
                        '<linearGradient id="azure_e382d742-7d51-4974-a256-24e182eef053-77dabbd6" x1="-947.292" y1="289.594" x2="-868.363" y2="79.308" gradientTransform="matrix(1 0 0 -1 1075 318)" gradientUnits="userSpaceOnUse">'+
                            '<stop offset="0" stop-color="#3ccbf4"></stop>'+
                            '<stop offset="1" stop-color="#2892df"></stop>'+
                        '</linearGradient>'+
                        '</defs>'+
                        '<path d="M89.158 18.266h69.238L86.523 231.224a11.041 11.041 0 01-10.461 7.51H22.179a11.023 11.023 0 01-10.445-14.548l66.963-198.41a11.04 11.04 0 0110.461-7.51z" fill="url(#azure_ab40b385-f958-420f-bfab-714be1677426-4a336842)"></path>'+
                        '<path d="M189.77 161.104H79.976a5.083 5.083 0 00-3.468 8.8l70.552 65.847a11.091 11.091 0 007.567 2.983h62.167z" fill="#0078d4"></path>'+
                        '<path d="M89.158 18.266a10.95 10.95 0 00-10.483 7.654L11.817 224.006a11.01 11.01 0 0010.393 14.728h55.274a11.814 11.814 0 009.069-7.714l13.33-39.29 47.625 44.418a11.267 11.267 0 007.089 2.586h61.937l-27.166-77.63-79.19.018 48.47-142.856z" fill="url(#azure_f40af90d-72eb-49b3-94b2-2510f1071722-6b298772)"></path>'+
                        '<path d="M177.592 25.764a11.023 11.023 0 00-10.444-7.498H89.984a11.024 11.024 0 0110.445 7.498l66.967 198.421a11.024 11.024 0 01-10.445 14.549h77.164a11.024 11.024 0 0010.444-14.549z" fill="url(#azure_e382d742-7d51-4974-a256-24e182eef053-77dabbd6)"></path>'+
                        '</svg>');
                        var icon_google = $('<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/><path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/><path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/><path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/><path d="M1 1h22v22H1z" fill="none"/></svg>');
                        var text = '';
                        if(oauth_type === 'azure-saml2') {
                            icon = icon_azure
                            text = $.i18n._('nixps-cloudflow-login.sign_in_with_azure');
                        } else if(oauth_type === 'google') {
                            icon = icon_google;
                            text = $.i18n._('nixps-cloudflow-login.sign_in_with_google');
                        }
                        var leftSidebutton = $('<div>').addClass('icon_'+pData.result[i]).append(icon);
                        var rightSidebutton = $('<span>').addClass('button_'+pData.result[i]).text(text);
                        //.attr("data-client_id", "YOUR_GOOGLE_CLIENT_ID").attr("data-login_uri", "https://your.domain/your_login_endpoint").attr("data-auto_prompt", "false")
                        var oauth_html = $('<div>').addClass(pData.result[i]+'_oauth2').append(leftSidebutton);
                        oauth_html.append(rightSidebutton);
                        oauth_html.on("click", (function(pEvent) {
                            oauthHandler(oauth_type);
                        }));
                        // var oauth_html = $('<div>').addClass(pData.result[i]+'_oauth2').text('Sign in with '+pData.result[i]);
                        $('#oauth2').addClass('oauth2').append(oauth_html);
                    }
                    
                    
                }
            }
            return pDefer.resolve();
        }, function (pData) {
            /* ignore error */
            return pDefer.reject(pData);
        });
    });

    
}

function oauthHandler(pInput) {
    api_defer.auth.generate_login_url(pInput).then(function(pResult) {
        window.location = pResult.result;
    });
}
//check if user has multiple scopes and add dropdown if so
// function check_scopes() {
//     api_async.users.get_all_scopes_for_user($('#username').val(), function(list_scopes){
//         $('#scope_options').parent('.cf-form-group').remove();
//         if (list_scopes !== undefined && Array.isArray(list_scopes.scopes) && list_scopes.scopes.length > 1) {
//             if ($('#scope_options').length === 0) {
//                 $('<div class="cf-form-group cf-form-add-gutter"><select class="cf-form-control cf-form-col-12" id="scope_options"></select></div>').insertAfter($('#password').parent('.cf-form-group'));
//             }
//             list_scopes.scopes.forEach(function (scope, index) {
//                 $('#scope_options').append($('<option>').val(scope.name).text(scope.name));
//             });
//         }
//     }, function(pError){
//         console.error(pError);
//     });
// }

function tabReOpenHandler(pEvent) {
    if (document.hidden === false && typeof window.sOrigURL === "string" && window.sOrigURL.length > 0) {
        var user_id = api.get_cookie("user_id");
        if (typeof user_id === "string" && user_id.length > 0) {
            var expiration_date = api.get_cookie("expiration_date");
            if (typeof expiration_date === "string" && expiration_date.length > 0) {
                var nowMinOneHour = (new Date()).setHours((new Date()).getHours() - 1);
                if (+(expiration_date + "000") > nowMinOneHour) {
                    document.location.assign(window.sOrigURL);
                }
            }
        } else {
            api_defer.auth.get_identity_providers().then(function(pResult) {
                if(pResult.result !== undefined && pResult.result.length > 0) {
                    document.location.assign(window.sOrigURL);
                }
            })
        }
    }
}

/**
 * Entry point of the login page
 */
function loginStart() {
    /* reset sessions */
    if (window.sessionStorage !== undefined) {
        sessionStorage.clear();
    }

    $(document).on("visibilitychange", tabReOpenHandler);

    var prefsPromise = api_defer.preferences.get_for_current_user('', 'language');
    $.when(prefsPromise, Version.get()).then(function (prefs, version) {
        var language = prefs.preferences;
        var build = version.getBuild();
        return $.when($.get('/cloudflow_' + language + '.json?' + build), language, version.getVersionObject());
    }).then(function (translations, language, pVersionObject) {
        $.i18n.setDictionary(translations[0]);
        $('body').find("span.translate").each(function(index, element) {
            var e = $(element);
            e._t(e.attr('key'));
        });
        renderLogin(language, pVersionObject);
    }).fail(function () {
        $.get('/cloudflow_en.json?' + (new Date().getTime())).then(function(p_translations) {
            $.i18n.setDictionary(p_translations);
            $('body').find("span.translate").each(function(index, element) {
                var e = $(element);
                e._t(e.attr('key'));
            });
            renderLogin('en');
        }).fail(function (pError) {
            console.error(pError);
		    // even translations faild, so we can not translate error message :-(
            $("#loginStatus").text("Unable to setup log in. Retry later.").closest('.cf-form-group').addClass('cf-form-group-has-error');
        });
    });

}

module.exports = {
    loginStart: loginStart,
    renderLogin: renderLogin
}